import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Slider from "../components/home/SliderComp2";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import useLocalStorage from "../components/useLocalStorage";
import TopBar from "../components/Layout/TopBar";
// import SEO from "../components/seo"
import SEO from "../components/seo";
import Seo from "../components/seo";
import EntryFormButton from "../components/EntryFormButton";
import { css } from "@emotion/core";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import tw from "tailwind.macro";
import GatsbyImage from "gatsby-image";
import hero from '../images/comp/prize-img.jpg';
import hero2 from '../images/comp/placeholder.png';
import { Field, Form, Formik, ErrorMessage } from "formik";
import { MdKeyboardArrowRight } from "react-icons/md";
import * as yup from "yup";
import { FaSpinner } from "react-icons/fa";

// import "../scss/main.scss";

export default function Story2024() {

  return (
    <>{ /* <SEO title="Welcome" keywords={[`great`, `british`, `dogs`]} /> */ }
    <SEO
      title="greatbritishdogs.co.uk | DOTY Voting 2024"
      keywords={[`great`, `british`, `dogs`]}
    />
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <div css={css`
                background-color: #004030 !important;
                min-height: calc(100vh - 84px) !important;
                `}>
            <div css={css`
                width: 100% !important; max-width: 1060px !important; padding-top: 30px !important; padding-right: 1rem !important; padding-left: 1rem !important; margin: 0 auto !important;`}>
            <h1 css={css`
                display: none !important;
                `}>
             Cast your vote for a chance to win
            </h1>
            <h2 css={css`
                display: none !important;
                `}>Want to help Winalot<sup>®</sup> crown Dog of the Year 2024? Vote for your favourite now for a chance to win a £100 Amazon voucher</h2>
            <div class="flex">
                <iframe src="https://www.greatbritishdogs.co.uk/doty-2024-form/" class="iframe-adjuster2"></iframe>
            </div>
            <br /><br />
          </div>
      </div>
      </>
    );
}